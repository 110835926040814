import React, { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import dynamic from 'next/dynamic';

interface ImageWithFallbackProps extends ImageProps {
	fallbackSrc: string;
}

// Helper function to safely get string URL from various source types
const getImageUrl = (src: any): string => {
	if (!src) return '';
	if (typeof src === 'string') return src;
	if (typeof src === 'object' && src.src) return src.src;
	return '';
};

const ImageWithFallback = (props: ImageWithFallbackProps) => {
	const { src, fallbackSrc, alt, quality, width, height, ...rest } = props;
	const [imgSrc, setImgSrc] = useState<string>(fallbackSrc);
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		if (isClient) {
			const sourceUrl = getImageUrl(src) || fallbackSrc;
			setImgSrc(sourceUrl);
		}
	}, [src, fallbackSrc, isClient]);

	// During SSR or before hydration, return a placeholder
	if (!isClient) {
		return (
			<div
				style={{
					position: 'relative',
					overflow: 'hidden',
					width: typeof width === 'number' ? `${width}px` : width,
					height: typeof height === 'number' ? `${height}px` : height,
					backgroundColor: 'var(--chakra-colors-gray-800)',
				}}
			/>
		);
	}

	return (
		<Image
			{...rest}
			width={width}
			height={height}
			alt={alt || 'Image'}
			src={imgSrc}
			quality={quality}
			onError={() => {
				setImgSrc(fallbackSrc);
			}}
		/>
	);
};

// Export a client-side only version of the component
export default dynamic(() => Promise.resolve(ImageWithFallback), {
	ssr: false,
});
