export const colors = {
	colors: {
		gdl: 'rgba(250, 232, 70, 1)',
		gdr: 'rgba(71, 139, 162, 1)',
		alert_gdl: '#ed4050',
		alert_gdr: '#71a247',
		info_gdl: '#4299e1',
		info_gdr: '#0bc5ea',
		warning_gdl: '#ed8936',
		warning_gdr: '#ecc94b',
		white: '#ffffff',
		cardBackground: 'rgba(255, 255, 255, 0.19)',
	},
};
