/**
 * Pinata direct upload utilities
 *
 * These functions handle direct uploads to Pinata IPFS service,
 * bypassing server-side limitations.
 */

import axios from 'axios';

/**
 * Get a temporary upload token from our API
 * @param retryCount Number of retries attempted so far
 * @returns A JWT token for Pinata uploads
 */
export const getPinataUploadToken = async (retryCount = 0): Promise<string> => {
	try {
		const response = await axios.post('/api/pinata/get-upload-token');

		if (!response.data.success || !response.data.token) {
			throw new Error(response.data.error || 'Failed to get upload token');
		}

		return response.data.token;
	} catch (error) {
		// Log detailed error information
		if (axios.isAxiosError(error)) {
			console.error('Error getting Pinata upload token:', {
				message: error.message,
				status: error.response?.status,
				statusText: error.response?.statusText,
				data: error.response?.data,
				config: {
					url: error.config?.url,
					method: error.config?.method,
				},
			});
		} else {
			console.error('Error getting Pinata upload token:', error);
		}

		// Implement retry logic (max 3 retries)
		if (retryCount < 3) {
			console.log(`Retrying token request (attempt ${retryCount + 1})...`);
			// Exponential backoff: wait longer between each retry
			await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
			return getPinataUploadToken(retryCount + 1);
		}

		throw error;
	}
};

/**
 * Upload a file directly to Pinata Public IPFS
 *
 * @param file The file to upload
 * @param onProgress Optional progress callback
 * @param retryCount Number of retries attempted so far
 * @returns The CID of the uploaded file
 */
export const uploadDirectToPinata = async (file: File, onProgress?: (progress: number) => void, retryCount = 0): Promise<string> => {
	try {
		// Get a temporary upload token
		const token = await getPinataUploadToken();

		// Create form data
		const formData = new FormData();
		formData.append('file', file);

		// Set metadata to ensure proper naming
		const metadata = JSON.stringify({
			name: file.name,
		});
		formData.append('pinataMetadata', metadata);

		// Add pinataOptions to ensure CIDv1 is used
		const options = JSON.stringify({
			cidVersion: 1,
		});
		formData.append('pinataOptions', options);

		// Use the public IPFS pinning endpoint instead of the private one
		const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: onProgress
				? (progressEvent) => {
						const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded));
						onProgress(progress);
					}
				: undefined,
			// Set a longer timeout for large files
			timeout: 5 * 60 * 1000, // 5 minutes
		});

		// Check for successful response - the structure is different for public IPFS
		if (!response.data || !response.data.IpfsHash) {
			throw new Error('Invalid response from Pinata');
		}

		// Return the CID (IpfsHash for public IPFS)
		return response.data.IpfsHash;
	} catch (error) {
		console.error('Error uploading to Pinata:', error);

		// Check if this is a token error (401 Unauthorized) or network error
		const axiosError = error as any;
		const isTokenError = axiosError.response && (axiosError.response.status === 401 || axiosError.response.status === 403);

		const isNetworkError = axiosError.code === 'ECONNABORTED' || axiosError.code === 'ETIMEDOUT' || !axiosError.response;

		// Implement retry logic (max 3 retries)
		if ((isTokenError || isNetworkError) && retryCount < 3) {
			console.log(`Retrying upload (attempt ${retryCount + 1})...`);
			// Exponential backoff: wait longer between each retry
			await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
			return uploadDirectToPinata(file, onProgress, retryCount + 1);
		}

		throw error;
	}
};

/**
 * Upload an image to Pinata with compression
 *
 * @param file The image file to upload
 * @param options Compression options
 * @param onProgress Optional progress callback
 * @returns Object with original and compressed CIDs
 */
export const uploadImageWithCompression = async (
	file: File,
	options: {
		maxSizeMB?: number;
		useWebWorker?: boolean;
	} = {},
	onProgress?: (progress: number) => void
): Promise<{ originalCid: string; compressedCid: string }> => {
	try {
		// Import imageCompression dynamically to avoid SSR issues
		const imageCompression = (await import('browser-image-compression')).default;

		// Upload original file first
		const originalCid = await uploadDirectToPinata(file, onProgress ? (progress) => onProgress(progress / 2) : undefined);

		// Compress the image
		const compressionOptions = {
			maxSizeMB: options.maxSizeMB || 1,
			useWebWorker: options.useWebWorker !== false,
			alwaysKeepResolution: true,
		};

		const compressedFile = await imageCompression(file, compressionOptions);

		// Upload compressed file
		const compressedCid = await uploadDirectToPinata(
			compressedFile,
			onProgress ? (progress) => onProgress(50 + progress / 2) : undefined
		);

		return {
			originalCid,
			compressedCid,
		};
	} catch (error) {
		console.error('Error uploading image with compression:', error);
		throw error;
	}
};
