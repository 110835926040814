import React, { useEffect, useState } from 'react';
import { Center, Container, Flex, Box, Text } from '@chakra-ui/react';
import { ErrorProps } from 'next/error';
import { ArkErrorProps } from './types';
import dynamic from 'next/dynamic';
import type { IPlayerProps } from '@lottiefiles/react-lottie-player';

// Import Lottie Player dynamically with SSR disabled
const Player = dynamic<IPlayerProps>(() => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player), {
	ssr: false,
	loading: () => <Box width="400px" />,
});

export const Error: React.FC<ErrorProps & ArkErrorProps> = ({ title, statusCode, onClick }) => {
	// State to track if we're on the client side
	const [isMounted, setIsMounted] = useState(false);

	// Set mounted state on component mount
	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<Container maxW="10xl">
			<Flex position="relative">
				<Flex w={'100%'} margin={'auto'}>
					<Box rounded="md" width="100%" p="8" onClick={onClick}>
						{isMounted && (
							<Player
								autoplay
								loop
								src="https://assets10.lottiefiles.com/packages/lf20_yrlntqy3.json"
								style={{ width: '400px' }}
							/>
						)}
						<Center>
							<Text sx={{ fontWeight: 'bold' }}>{statusCode ? statusCode : '404'}</Text>
							<Text ml={2}>| {title ? title : 'Page Not Found'}</Text>
						</Center>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
};
