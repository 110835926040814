import React, { useEffect, useState } from 'react';
import { Center, Container, Flex, Box } from '@chakra-ui/react';
import { ArkErrorProps } from './types';
import dynamic from 'next/dynamic';
import type { IPlayerProps } from '@lottiefiles/react-lottie-player';

// Import Lottie Player dynamically with SSR disabled
const Player = dynamic<IPlayerProps>(() => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player), {
	ssr: false,
	loading: () => <Box height="300px" width="300px" />,
});

export const AccessDenied: React.FC<ArkErrorProps> = ({ onClick }) => {
	// State to track if we're on the client side
	const [isMounted, setIsMounted] = useState(false);

	// Set mounted state on component mount
	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<Container maxW="2xl">
			<Flex position="relative">
				<Flex w={'100%'} margin={'auto'}>
					<Box rounded="md" width="100%" p="8" onClick={onClick}>
						{isMounted && (
							<Player
								autoplay
								loop
								src="https://assets3.lottiefiles.com/packages/lf20_jnhl4zb7.json"
								style={{ height: '300px', width: '300px' }}
							/>
						)}
						<Center>
							<b>403 |</b> &nbsp; Access Denied for this Page
						</Center>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
};
