import { AlchemyProvider, ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethNetwork, magic } from '@theark/react-common';
import { ProviderPreference } from '../store/types';
import { Config, createStarkSigner, generateLegacyStarkPrivateKey, ImmutableX, WalletConnection } from '@imtbl/core-sdk';
import axios from 'axios';
import FormData from 'form-data';

export const ProfileCollectionTabs: Array<string> = ['All', 'Trending', 'Art', 'Collections', 'Music', 'Photography', 'Sports'];

// Define the custom network
// const customNetwork = {
// 	name: 'sepolia',
// 	chainId: 11155111,
// };

// // Create a new JsonRpcProvider with the custom network
// const customProvider = new ethers.JsonRpcProvider('https://sepolia.infura.io/v3/7e920f8d1337476f81ea57c594a76baa', customNetwork);

// Now you can use `customProvider` as a regular ethers.js provider
// export const getDefaultProvider = ethers.getDefaultProvider(ethNetwork);

export const getDefaultProvider = new AlchemyProvider(ethNetwork, process.env.NEXT_ALCHEMY_API);

export const getExternalProvider = async (providerType?: ProviderPreference | null): Promise<unknown> => {
	switch (providerType) {
		case ProviderPreference.METAMASK:
			const provider = await detectEthereumProvider({ mustBeMetaMask: true });
			// set correct permissions
			const tmpProvider = provider as ExternalProvider;
			if (!tmpProvider.isStatus && tmpProvider.request) {
				try {
					await tmpProvider.request({ method: 'eth_accounts' });
				} catch {
					await tmpProvider.request({
						method: 'wallet_requestPermissions',
						params: [
							{
								eth_accounts: {},
							},
						],
					});
				}
			}
			return provider;
		case ProviderPreference.MAGIC_LINK:
			return magic?.rpcProvider;
		default:
			return magic?.rpcProvider;
	}
};

export const getWeb3Provider = async (provider: ExternalProvider | JsonRpcFetchFunc) => {
	return new Web3Provider(provider);
};

const config = ethNetwork === 'mainnet' ? Config.PRODUCTION : Config.SANDBOX;
export const IMX_CLIENT = new ImmutableX(config);

console.log(ethNetwork);

export const getStarkPublicKey = async (address: string, provider: Web3Provider) => {
	const usersApi = IMX_CLIENT.usersApi;
	const user = await usersApi
		.getUsers({ user: address })
		.then((user) => {
			return user.data.accounts[0];
		})
		.catch(async () => {
			console.log('registering user on imx');
			const walletConn = await generateWalletConnection(provider);
			await IMX_CLIENT.registerOffchain(walletConn);
			return walletConn.starkSigner.getAddress();
		});
	return user;
};

export const generateWalletConnection = async (l1Provider: Web3Provider): Promise<WalletConnection> => {
	// L1 credentials
	const l1Signer = l1Provider.getSigner();

	// L2 credentials
	const starkPrivateKey = await generateLegacyStarkPrivateKey(l1Signer);
	const l2Signer = createStarkSigner(starkPrivateKey);

	return {
		ethSigner: l1Signer,
		starkSigner: l2Signer,
	};
};

export const formatDate = (date: string | null) => {
	if (!date) return new Date();
	const dateObj = new Date(date);
	return dateObj;
};
export const uploadToPinata = async (
	file: { buffer: Buffer; type: string | undefined; name: string },
	options?: {
		maxSizeBytes?: number;
		onProgress?: (progress: number) => void;
	}
): Promise<string> => {
	// File size validation
	const maxSizeBytes = options?.maxSizeBytes || 400 * 1024 * 1024; // Default to 400MB
	if (file.buffer.length > maxSizeBytes) {
		throw new Error(`File size exceeds the ${Math.floor(maxSizeBytes / (1024 * 1024))}MB limit`);
	}

	// Log file info
	console.log('Processing file for Pinata:', {
		filename: file.name,
		size: file.buffer.length,
		mimetype: file.type,
	});

	// Create form data
	const data = new FormData();
	data.append('file', file.buffer, {
		filename: file.name,
		contentType: file.type,
	});

	const pinataOptions = JSON.stringify({
		cidVersion: 1,
	});
	data.append('pinataOptions', pinataOptions);

	try {
		// Upload to Pinata with proper configuration
		const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', data, {
			headers: {
				Authorization: `Bearer ${process.env.PINATA_JWT}`,
				...data.getHeaders(), // This ensures that FormData headers are correctly set
				'Content-Type': `multipart/form-data; boundary=${data.getBoundary()}`,
			},
			maxBodyLength: Infinity,
			maxContentLength: Infinity,
			onUploadProgress: options?.onProgress
				? (progressEvent) => {
						const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded));
						options.onProgress?.(progress);
					}
				: undefined,
		});

		if (!res.data || !res.data.IpfsHash) {
			throw new Error('Failed to get IPFS hash from Pinata');
		}

		console.log('Pinata upload successful, CID:', res.data.IpfsHash);
		return res.data.IpfsHash;
	} catch (error) {
		console.error('Pinata upload error:', error);
		throw error; // Re-throw to allow calling code to handle specific errors
	}
};

// Export Pinata utilities
export * from './pinata';
